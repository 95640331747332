import React, { useEffect, useState, useRef } from "react";
import UserAlreadyExists from '../Components/Assets/already exists.jpg';
import { MdOutlineHealthAndSafety } from "react-icons/md"
import { RiMicroscopeLine, RiHeartLine, } from "react-icons/ri";
import { HiOutlineUserCircle } from "react-icons/hi";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper";
import MGR from '../Components/Assets/MGR.jpg'
import NameBanner from '../Components/Assets/pharmacynamebanner.png'
import MobileNameBanner from '../Components/Assets/mobilepharnamebanner.png'
import Curriculum from "../Components/Assets/Curriculum.webp"
import axios from "axios";
import "react-responsive-modal/styles.css";
import Eligibility from "../Components/Assets/Eligibility.webp"
import { MBAAF } from "../Components/Json/MBA-DM"
import { BsCheck2All } from "react-icons/bs";
import $ from 'jquery';
import InternShip from "../Components/Hub-Components/InternShip";

function CoursePage() {

    const scrollHideDiv = useRef(null)
    const reference = useRef()
    const [userExists, setUserExists] = useState(false)

    const [values, setValue] = useState({
        name: "",
        email: "",
        phone: "",
        course: "Bachelor of Pharmacy",
        parameter: ""
    })

    userExists ? document.body.style.overflow = "hidden" : document.body.style.overflow = "auto";

    useEffect(() => {
        document.addEventListener("mousedown", (event) => {
            if (!reference.current.contains(event.target)) {
                setUserExists(false)
            }
        })
    })

    const [errors, setErrors] = useState({})
    const [isSaveClick, setIsSaveClick] = useState(false)
    useEffect(() => {

        window.addEventListener("scroll", (event) => {
            var y = window.scrollY;
            if (y >= 600) {
                $("#scrolling").fadeIn("slow");
            } else if (y <= 600) {
                $("#scrolling").fadeOut(2000);
            }
        })
    }, [])

    const [urlAds, seturlAds] = useState("Lead")
    const date = new Date().getFullYear()

    useEffect(() => {
        var url = window.location.href;
        url = url.replace("?", '');
        var newnul = url.split(/([_,=,&,-,-,+,/,:])/)
        newnul.forEach((el) => {
            const element = el;
            switch (element) {
                case "ads":
                    seturlAds("ads")
                    break;
                case "google":
                    seturlAds("google")
                    break;
                case "Google":
                    seturlAds("google")
                    break;
                case "gooGle":
                    seturlAds("google")
                    break;
                case "GooGle":
                    seturlAds("google")
                    break;
                case "youtube":
                    seturlAds("youtube")
                    break;
                case "Youtube":
                    seturlAds("youtube")
                    break;
                case "youTube":
                    seturlAds("youtube")
                    break;
                case "YouTube":
                    seturlAds("youtube")
                    break;
                case "facebook":
                    seturlAds("facebook")
                    break;
                case "Facebook":
                    seturlAds("facebook")
                    break;
                case "FaceBook":
                    seturlAds("facebook")
                    break;
                case "faceBook":
                    seturlAds("facebook")
                    break;
                case "Instagram":
                    seturlAds("Instagram")
                    break;
                case "InstaGram":
                    seturlAds("Instagram")
                    break;
                case "instagram":
                    seturlAds("Instagram")
                    break;
                default:
                    break;
            }

        })
    }, [])

    const handleChange = (e) => {
        const { name, value } = e.target
        setValue({
            ...values,
            [name]: value
        })
        if (isSaveClick === true) {
            setErrors(errorHandler(values))
        }
    }


    const errorHandler = () => {
        var error = {}
        let regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
        let Number = /^[0-9]/
        if (values.name === "") {
            error.name = "Enter the Name"
        }
        if (values.email === "") {
            error.email = "Enter the Email"
        } else if (!regex.test(values.email)) {
            error.email = "Invalid email type"
        }
        if (values.phone === "") {
            error.phone = "Enter the phone"
        } else if (!Number.test(values.phone)) {
            error.phone = "must be number "
        } else if (values.phone.length !== 10) {
            error.phone = "must be number 10 number"
            values.phone = ""
        }
        if (values.course === "") {
            error.course = "Enter the course"
        }
        return error
    }


    const handleSubmit = (event) => {
        event.preventDefault();
        setIsSaveClick(true)
        var noerror = errorHandler(values)
        setErrors(noerror)
        if (Object.keys(noerror).length === 0) {
            var data = {};
            const params = new URLSearchParams({
                name: values.name,
                email: values.email,
                phone: values.phone,
                course: values.course,
                parameter: urlAds,
            }).toString();

            var btm = document.getElementById('submit')
            btm.disabled = true

            // console.log("https://rvscas.com/api/createadmission?" + params)
            const url = "https://rvscas.com/api/createadmission?" + params;
            // const url = "http://192.168.101.156:7000/api/createadmission?" + params;
            // console.log(values.parameter)
            axios.post(url, data)
                .then((response) => {

                    const toDataURL = url => fetch(url)
                        .then(response => response.blob())
                        .then(blob => new Promise((resolve, reject) => {
                            const reader = new FileReader()
                            reader.onloadend = () => resolve(reader.result)
                            reader.onerror = reject
                            reader.readAsDataURL(blob)
                        }))

                    toDataURL(require('../Components/Assets/BPharm.pdf'))

                        .then(dataUrl => {
                            localStorage.setItem("BPharmacy", "bbbppphhhaaarrrmmmaaacccyyy")
                            window.location = "/thankyou"
                            const element = document.createElement('a');
                            element.setAttribute('download', 'BPharm');
                            element.setAttribute('href', dataUrl);
                            element.style.display = 'none';
                            document.body.appendChild(element);
                            element.click();
                            document.body.removeChild(element);
                            setErrors({});

                        })
                    setValue({
                        name: "",
                        email: "",
                        phone: "",
                        course: "",
                    })
                    setErrors({})
                })
                .catch((error) => {
                    console.log(error)
                    if (error.response.status === 409) {
                        setUserExists(true)
                        setTimeout(() => {
                            setUserExists(false)
                        }, 5000)
                    }
                    btm.disabled = true
                })
        }
    };

    return (
        <>

            <div className="relative">
                {userExists === true ? <div className="justify-center bg-modal backdrop-blur-sm items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none sm:w-[100%] sm:px-3 ">
                    <div className=" scale-up-center" ref={reference}>
                        <img src={UserAlreadyExists} alt="fail" className="w-[550px] rounded-lg" />
                    </div>
                </div>
                    : <div></div>}
                <header class="category-title" id="header">
                    <div className="h-[100vh] relative " id="form">
                        <nav className="">
                            <div className="container mx-auto ">
                                <div className="center desktop-view-logo">
                                    <div className="w-[100%] center">
                                        <img src={NameBanner} alt="RVS Logo" className="logoCss mt-[10px] w-[60%] sm:w-[10%] pt-3 mx-auto" />
                                    </div>
                                </div>
                                <div className="flex  mobile-view w-[100%]">
                                    <div className="mobile-view-logo">
                                        <div className="w-[100%] center">
                                            <img src={MobileNameBanner} alt="RVS Logo" className="logoCss mobile-view-image py-4 mx-auto" />
                                        </div>
                                    </div>
                                    {/* <img src={IMSRMobile} alt="RVS Logo" className="logoCss mobile-view-image py-4 mx-auto" /> */}
                                </div>
                            </div>
                        </nav>
                        <div className="backgroundimage h-[100%] object-cover">
                        </div>
                        <div className="absolute bottom-[0%] sm:top-[16%] Hero w-[100%] lg:top-[24%] lge-[10%] lg:bottom-none md:top-[20%] " >
                            <div className="container mx-auto py-16 sm:py-0 mdsm:py-0">
                                <div className="w-[100%] flex sm:block md:block mdsm:block lg:flex" >
                                    <div className="w-[60%] center content sm:none sm:w-[100%] lg:w-[60%] md:w-[80%] mdsm:w-[80%] mdsm:mx-auto md:mx-auto md:pb-8 mdsm:pb-0">
                                        <div className="block sm:px-4 sm:w-[80%] md:-[80%] sm:mx-auto sm:pb-4">
                                            <p className="text-white title md:text-[24px] text-[48px] mdsm:text-[24px]  lg:text-[30px] text-left sm:text-[16px]">
                                                Admissions Open for the Year 2023-24
                                            </p>
                                            <h1 className="text-white title text-[48px] mdsm:text-[34px] lg:text-[60px] text-left sm:text-[18px]">{MBAAF?.title} </h1>
                                            <div className="w-[100%] mdsm:none mdsm:pt-4 ">
                                                <p className="sm:visible lg:hidden text-white text-h2 md:text-[24px] sm:pt-4 mdsm:leading-[40px] lg:text-[20px] lg:leading-[30px] mdsm:text-[16px] sm:text-[14px] sm:leading-[20px] leading-[36px] md:leading-[40px] text-justify">Empower lives through the art and science of pharmacy.</p>
                                                {/* <p className="text-white text-h2 md:text-[16px] NoneCss mdsm:leading-[26px] lg:text-[20px] lg:leading-[30px] mdsm:text-[16px] sm:text-[14px] sm:leading-[20px] leading-[36px] md:leading-[24px] text-justify">{MBAAF?.course_dis}</p> */}
                                                <p className="text-white text-h2 md:text-[16px] NoneCss mdsm:leading-[26px] lg:text-[20px] lg:leading-[30px] mdsm:text-[16px] sm:text-[14px] sm:leading-[20px] leading-[36px] md:leading-[24px] text-justify"> B.Pharm offers a comprehensive curriculum that builds a solid foundation in pharmaceutical sciences. It focuses on the vital connection between practitioners, patients, medicine design, manufacturing, and drug action. By completing this program, students are well-prepared to embark on careers in the pharmaceutical industry or pursue advanced studies in the field.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-[40%] sm:w-[100%] md:w-[70%] md:mx-auto  mdsm:w-[90%] mdsm:mx-auto lg:w-[40%]">
                                        <div className="flex lg:justify-center mdsm:justify-center  sm:justify-center md:justify-center">
                                            <form className="w-[80%] py-2" >
                                                <div>
                                                    <div className="bg-primary py-2 px-8">
                                                        <p className="text-center text-white text-[24px] sm:text-[16px] title">Get Your Brochure</p>
                                                    </div>
                                                    <div className="px-8 py-8 lg:py-8 lg:px-8 sm:px-6 sm:py-6 bg-pureWhite md:px-2 md:py-2 mdsm:py-4 mdsm:px-4">
                                                        <div className="mb-6 sm:mb-2 md:mb-2 mdsm:md-2 marginLabel lg:mb-6">
                                                            <input
                                                                type="text"
                                                                id="name"
                                                                name="name"
                                                                value={values.name}
                                                                onChange={(e) => handleChange(e)}
                                                                placeholder={`Name`}
                                                                className={`borderColor px-4 focus:outline-none focus:border-primary w-[100%] h-[40px] sm:h-[30px] md:h-[30px] mdsm:h-[30px] lg:h-[40px] sm:text-sm placeholder:text-text-color`}
                                                            />
                                                            <div className="flex justify-items-start px-4 pt-2">
                                                                {errors.name && <span className="text-red text-sm sm:text-[10px] text-center">{errors.name}</span>}
                                                            </div>
                                                        </div>

                                                        <div className="mb-6 sm:mb-2 md:mb-2 mdsm:md-2 marginLabel  lg:mb-6">
                                                            <input
                                                                type="text"
                                                                name="email"
                                                                id="email"
                                                                value={values.email}
                                                                onChange={(e) => handleChange(e)}
                                                                placeholder={`Email`}
                                                                className={`borderColor email px-4 focus:outline-none  focus:border-primary w-[100%] h-[40px] sm:h-[30px] md:h-[30px] mdsm:h-[30px] lg:h-[40px] placeholder:text-text-color sm:text-sm`} />
                                                            <div className="flex justify-items-start px-4 pt-2">
                                                                {errors.email && <span className="text-red text-sm sm:text-[10px] text-center">{errors.email}</span>}
                                                            </div>
                                                        </div>
                                                        <div className="mb-6 sm:mb-2 md:mb-2 mdsm:md-2 marginLabel  lg:mb-6">
                                                            <input type="text"
                                                                name="phone"
                                                                id="phone"
                                                                value={values.phone}
                                                                placeholder={`Mobile Number`}
                                                                className={`borderColor phone px-4 focus:outline-none focus:border-primary w-[100%] h-[40px] sm:h-[30px] md:h-[30px] mdsm:h-[30px] lg:h-[40px] placeholder:text-text-color sm:text-sm`}
                                                                onChange={(e) => handleChange(e)}
                                                                onKeyPress={(e) => { if (!/^[0-9]/.test(e.key)) { e.preventDefault() } }}
                                                                maxLength={10}
                                                                minLength={10}
                                                            />
                                                            <div className="flex justify-items-start px-4 pt-2">
                                                                {errors.phone && <span className="text-red text-sm sm:text-[10px] text-center">{errors.phone}</span>}
                                                            </div>
                                                        </div>
                                                        {/* <div className="mb-6 sm:mb-2 md:mb-2 mdsm:md-2 marginLabel  lg:mb-6">
                                                                <input
                                                                    disabled
                                                                    type="text"
                                                                    name="course"
                                                                    value={values.course}
                                                                    onChange={(e) => handleChange(e)}
                                                                    placeholder={`${errors.course ? "Enter Your Course" : "Course"}`}
                                                                    className={`borderColor-disable ${errors.course ? "placeholder:text-red error" : ""} px-4 focus:outline-none bg-[#ccdaf5] focus:border-primary w-[100%] h-[40px] sm:h-[30px] md:h-[30px] mdsm:h-[30px] lg:h-[40px] placeholder:text-primary sm:text-sm`} />
                                                                <div className="flex justify-items-start px-4 pt-2">
                                                                    {errors.course && <span className="text-red text-sm sm:text-[10px] text-center">{errors.course}</span>}
                                                                </div>
                                                            </div> */}
                                                        <button onClick={handleSubmit} type="submit" id="submit"
                                                            disabled={values.name !== '' && values.email !== '' && values.phone !== '' ? false : true}
                                                            className={`${values.name !== '' && values.email !== '' && values.phone !== '' ? "bg-primary hover:bg-[#081224]" : 'cursor-not-allowed bg-[#CCCCCC] text-black'} submit btn Transition text-[18px] borderradius sm:text-[14px]  text-pureWhite  px-14 py-2 tracking-wider`}>Submit </button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                {/*Header Section  */}


                {/*Quick Info  Section  */}

                {/* <section class="category-title" id="about">
                        <div className="container mx-auto ">
                            <div className="flex w-[100%] lg:flex mdsm:flex-wrap sm:flex sm:flex-wrap py-10 QuickinfoBoxBorder">
                                <div className="w-[25%] mdsm:w-[50%] lg:w-[25%] lg:pb-0 sm:w-[48%] sm:pb-8 mdsm:pb-8   QuickinfoBorder">
                                    <div className="w-[100%] flex">
                                        <div className="w-[40%] flex justify-end px-6 py-1">
                                            <HiOutlineCalendar className="text-h3 text-primary" />
                                        </div>
                                        <div className="w-[60%]">
                                            <div className="block">
                                                <p className="title text-h4 text-left text-secondary pb-2 sm:text-[18px]">Commencing From </p>
                                                <p className="text-base text-text-color text-left sm:text-[14px]">{MBAAF?.course_reg.course_start}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-[25%] sm:w-[48%] lg:w-[25%] lg:pb-0 mdsm:w-[50%]  mdsm:pb-8  sm:pb-8 smQuickinfoBorder">
                                    <div className="w-[100%] flex">
                                        <div className="w-[40%] flex justify-end px-6 py-1">
                                            <HiOutlineClock className="text-h3 text-primary" />
                                        </div>
                                        <div className="w-[60%]">
                                            <div className="block">
                                                <p className="title text-h4 text-left text-secondary pb-2 sm:text-[18px]">Duration </p>
                                                <p className="text-base text-text-color text-left sm:text-[14px]">{MBAAF?.course_reg.duration}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-[25%]  sm:w-[48%] lg:w-[25%] lg:pb-0 mdsm:w-[50%] mdsm:pb-8 sm:pb-8 QuickinfoBorder">
                                    <div className="w-[100%] flex">
                                        <div className="w-[40%] flex justify-end px-6 py-1">
                                            <HiOutlineLocationMarker className="text-h3 text-primary" />
                                        </div>
                                        <div className="w-[60%]">
                                            <div className="block">
                                                <p className="title text-h4 text-left text-secondary pb-2 sm:text-[18px]">Location  </p>
                                                <p className="text-base text-text-color text-left sm:text-[14px]">{MBAAF?.course_reg.location}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-[25%] sm:w-[48%] lg:w-[25%] lg:pb-0  mdsm:w-[50%] smQuickinfoBorder">
                                    <div className="w-[100%] flex">
                                        <div className="w-[40%] flex justify-end px-6 py-1">
                                            <HiOutlineUserRemove className="text-h3 text-primary" />
                                        </div>
                                        <div className="w-[60%]">
                                            <div className="block">
                                                <p className="title text-h4 text-left text-secondary pb-2 sm:text-[18px]">Specialization  </p>
                                                <p className="text-base text-text-color text-left sm:text-[14px]">{MBAAF?.course_reg.specialization}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> */}

                {/*ScolarShip and Accerdiation Section  */}

                <section class="category-title" id="ScolarShip">
                    <div className="container mx-auto ">
                        <div className="">
                            <div className="w-[100%] flex sm:block mdsm:block lg:flex">
                                {/*ScolarShip Section  */}
                                {/* <div className="w-[50%] sm:w-[100%] mdsm:w-[100%] md:w-[100%] lge:w-[100%] lg:w-[50%] ScolarShipPadding pl-6">
                                      
                                        <div className="text-left pb-3">
                                            <p className="title text-secondary lg:text-[40px] mdsm:text-[30px] sm:text-left sm:text-[24px] py-3 ">Vision</p>
                                            <p className="text-text-color text-base leading-[30px] sm:text-[14px] sm:pt-2 leading-wider pl-6">To be a center for excellence in education, research, and service that enhances the health and well-being of society.</p>
                                        </div>

                                        <div className="text-left">
                                            <p className="title text-secondary lg:text-[40px] mdsm:text-[30px] sm:text-left sm:text-[24px] py-3 ">Motto</p>
                                            <p className="text-text-color text-base leading-[30px] sm:text-[14px] sm:pt-2 leading-wider pl-6">The motto of the College is <strong>"SERVE WITH LOVE".</strong></p>
                                        </div>
                                    </div> */}

                                {/*ScolarShip Section  */}

                                {/*ScolarShip and Accerdiation Section  */}

                                <div className="w-[50%] sm:w-[100%] mdsm:w-[100%] md:w-[100%] lge:w-[100%] lg:w-[100%] AccerditationBgImage">
                                    <div className="w-[100%] ScolarShipPadding">
                                        <div className="px-10 sm:px-0">
                                            <div className="flex w-[100%]">
                                                <p className="title tracking-wider mdsm:text-[30px] text-pureWhite lg:text-[40px] py-3 ">Accreditation</p>
                                            </div>
                                            <div className="pt-8">
                                                {/* <p className="text-white text-justify sm:text-sm leading-[30px]">Lorem ipsum dolor sit consectetur adipiscing elit do eiusmod tempor incididunt ut labore et dolore.</p> */}
                                            </div>
                                            <div className="relative">
                                            </div>
                                            <Swiper
                                                slidesPerView={1}
                                                autoHeight={true}
                                                spaceBetween={10}
                                                navigation={true}
                                                pagination={{
                                                    clickable: true,
                                                }}
                                                modules={[Navigation, Pagination]}
                                                className="mySwiper"
                                                breakpoints={{
                                                    640: {
                                                        slidesPerView: 2,
                                                        spaceBetween: 20,
                                                    },
                                                    768: {
                                                        slidesPerView: 4,
                                                        spaceBetween: 40,
                                                    },
                                                    1024: {
                                                        slidesPerView: 2,
                                                        spaceBetween: 50,
                                                    },
                                                }}
                                            >
                                                <SwiperSlide><img src={MGR} alt="NAAC" className="sm:mx-auto" /></SwiperSlide>
                                                {/* <SwiperSlide><img src={NeedImage} alt="Bharathiyar" className="sm:mx-auto" /></SwiperSlide> */}
                                                {/* <SwiperSlide><img src={NeedImage} alt="AICTE" className="sm:mx-auto" /></SwiperSlide> */}
                                            </Swiper>
                                        </div>
                                    </div>
                                </div>
                                {/*ScolarShip and Accerdiation Section  */}
                            </div>
                        </div>
                    </div>
                </section>

                {/*Programs Highlighted Section  */}
                <div className="mt-10">
                </div>

                {/*Programs Highlighted Section  */}


                {/*About Course Section  */}

                {/*About Course Section  */}


                {/*programme Section  */}

                <section class="category-title" id="about-course">
                    <div className="ProgrammeSection">
                        <div className="container mx-auto">
                            <div className="title sm:pt-16 lg:text-[40px] mdsm:text-[30px] sm:text-[24px] text-secondary">
                                Who should do the course?
                            </div>
                            <div className="w-[100%] pt-14 py-10 flex sm:pb-3 sm:block mdsm:flex-wrap">
                                <div className="w-[25%] group sm:w-[100%] mdsm:w-[50%] lg:w-[25%]  px-6 sm:pb-8">
                                    <div className="flex justify-center">
                                        <MdOutlineHealthAndSafety className="text-primary text-[98px] sm:text-[46px]" />
                                    </div>
                                    {/* <div className="text-text-color Transition group-hover:text-secondary sm:pt-2 text-center pt-4 sm:text-[16px] title text-[24px]">Graduates </div> */}
                                    <p className="text-text-color text-base pt-4 leading-[30px] sm:text-[14px] sm:pt-2 leading-wider">Individuals interested in the pharmaceutical industry and drug development.</p>
                                </div>
                                <div className="w-[25%] group sm:w-[100%] mdsm:w-[50%] lg:w-[25%]  px-6 sm:pb-8">
                                    <div className="flex justify-center">
                                        <HiOutlineUserCircle className="text-primary text-[98px] sm:text-[60px]" />
                                    </div>
                                    {/* <div className="text-text-color Transition group-hover:text-secondary sm:pt-2 text-center pt-4 sm:text-[16px] title text-[24px]">Professionals</div> */}
                                    <p className="text-text-color text-base pt-4 leading-[30px] sm:text-[14px] sm:pt-2 leading-wider">Students who have completed their higher secondary education with a science background.</p>
                                </div>
                                <div className="w-[25%] group sm:w-[100%] mdsm:w-[50%] lg:w-[25%]  px-6 sm:pb-8">
                                    <div className="flex justify-center">
                                        <RiMicroscopeLine className="text-primary text-[98px] sm:text-[44px]" />
                                    </div>
                                    {/* <div className="text-text-color Transition group-hover:text-secondary sm:pt-2 text-center pt-4 sm:text-[16px] title text-[24px]">Individuals </div> */}
                                    <p className="text-text-color text-base pt-4 leading-[30px] sm:text-[14px] sm:pt-2 leading-wider">Individuals who want to pursue a career as a pharmacist or work in pharmaceutical research and development.</p>
                                </div>
                                <div className="w-[25%] group sm:w-[100%] mdsm:w-[50%] lg:w-[25%]  px-6 sm:pb-8">
                                    <div className="flex justify-center">
                                        <RiHeartLine className="text-primary text-[98px] sm:text-[50px]" />
                                    </div>
                                    {/* <div className="text-text-color Transition group-hover:text-secondary text-center pt-4 sm:pt-2 sm:text-[16px] title text-[24px]">Sales person </div> */}
                                    <p className="text-text-color text-base pt-4 sm:pt-2 leading-[30px] sm:text-[14px] leading-wider">Students looking to gain a deep understanding of pharmaceutical sciences and healthcare-related subjects.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/*programme Section  */}


                {/*Curriculum Section  */}
                <div className="pt-10">
                    <InternShip />
                </div>


                <section class="category-title" id="curriculums">

                    <div className="CurriculumSection">
                        <div className="container mx-auto py-10 sm:py-4">
                            <div className="title pb-5 sm:pb-4 center sm:text-center mdsm:text-center lg:text-left sm:px-4 text-[40px] lg:text-[40px] sm:text-[24px] md:text-[30px] text-secondary">
                                Program Curriculum
                            </div>
                            <div className="w-[100%] lg:pb-0 flex sm:block sm:p-4 md:flex">
                                <div className="w-[70%] sm:w-[100%] mdsm:w-[100%] lg:w-[70%] md:w-[100%]">
                                    <p id="curriculum" className="text-text-color text-base sm:text-[14px] leading-[30px] text-left"></p>
                                    {/* <p className="text-text-color  sm:text-[14px] leading-[30px] text-left">{MBAAF?.program_curriculum}</p> */}
                                    <div className=" sm:px-1 pt-4 sm:pt-0">
                                        <div className="w-[100%] flex lg:flex mdsm:block sm:block ">
                                            <div className="w-[50%] group mdsm:w-[100%] lg:w-[50%] sm:w-[100%] ">
                                                {/* <h4 className="text-text-color Transition  pb-2 text-left pt-2 sm:text-[16px] title text-[24px]">Year I</h4> */}
                                                {/* <p className="text-text-color text-left text-base pb-5 sm:text-[14px]">As a Major  Specialization, you can opt for either of the following</p> */}
                                                <div className="px-4 ">
                                                    {MBAAF?.major_specialization.map((v, i) => {
                                                        return (
                                                            <div>
                                                                <div keys={i} className="w-[100%] pb-6  flex">
                                                                    <div className="w-[10%] sm:w-[8%]">
                                                                        <BsCheck2All className="text-h3 text-primary sm:text-h5" />
                                                                    </div>
                                                                    <div className="w-[90%]">
                                                                        <p className="text-text-color text-base sm:text-sm text-left">
                                                                            {v}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}


                                                </div>
                                            </div>

                                            <div className="w-[50%]   group mdsm:w-[100%] lg:w-[50%] sm:w-[100%] lg:pl-4">
                                                {/* <h4 className="text-text-color group-hover:texct Transition  pb-2 text-left pt-2 sm:text-[16px] lg:pt-2 mdsm:pt-0 sm:pt-0 title text-[24px]">Year II</h4> */}
                                                <div className="">
                                                    {/* <p className="text-text-color text-base text-left pb-5 sm:text-[14px]">As a Minor Specialization, you can opt for either of the following</p> */}
                                                    <div className="px-4">
                                                        {MBAAF?.minor_specialization.map((v, i) => {
                                                            return (
                                                                <div key={i} className="w-[100%] pb-6 flex">
                                                                    <div className="w-[10%] sm:w-[8%]">
                                                                        <BsCheck2All className="text-h3 text-primary sm:text-h5" />
                                                                    </div>
                                                                    <div className="w-[90%]">
                                                                        <p className="text-text-color text-base sm:text-sm text-left">
                                                                            {v}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div className="w-[50%] group mdsm:w-[100%] lg:w-[50%] sm:w-[100%] ">
                                                <h4 className="text-text-color Transition  pb-2 text-left pt-2 sm:text-[16px] title text-[24px]">Third Professional</h4>
                                                <div className="px-4 ">
                                                    {MBAAF?.third_professional.map((v, i) => {
                                                        return (
                                                            <div>
                                                                <div keys={i} className="w-[100%] pb-6  flex">
                                                                    <div className="w-[10%] sm:w-[8%]">
                                                                        <BsCheck2All className="text-h3 text-primary sm:text-h5" />
                                                                    </div>
                                                                    <div className="w-[90%]">
                                                                        <p className="text-text-color text-base sm:text-sm text-left">
                                                                            {v}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div> */}



                                        <div className="py-3 pt-10 pb-16 sm:pt-2">
                                            <div className="w-[100%] sm:px-6 lg:mx:auto flex sm:block mdsm:flex lg:flex">
                                                <div className=" sm:pb-4  mdsm:pb-6 lg:pb-0 mdsm:mr-10">
                                                    <div className="relative ">
                                                        <div className="text-text-color  pt-2 text-h4 sm:text-sm information text-left leading-[30px] ">
                                                            For information about the <span className="font-semibold title text-[22px] sm:text-[18px] tracking-wider text-primary">Curriculum</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className=" sm:w-[100%] flex justify-between">



                                                    <a href="#form">
                                                        <button className="bg-primary  Transition text-[18px] borderradius hover:bg-[#081224] text-pureWhite  px-14 py-2 tracking-wider ">
                                                            Download Brochure
                                                        </button>
                                                    </a>
                                                </div>

                                            </div>


                                        </div>


                                    </div>


                                </div>
                                <div className="w-[30%] px-6 NoneCss">
                                    <img src={Curriculum} alt="Curriculum " />

                                </div>

                            </div>
                        </div>


                    </div>
                </section>


                {/*Curriculum Section  */}

                {/*Learn from Expect Section  */}
                {/* <ALumnii /> */}
                <section class="category-title" id="learn-from-expect">
                    {/* <InternShip /> */}

                    {/* <div className="bg-primary">
                            <div className="LearnExectSection container  mx-auto">
                                <div className="sm:pt-16 title sm:text-[24px] mdsm:text-[30px] lg:pt-16  text-pureWhite tracking-wider lg:text-[40px]">
                                    Learn from Expect
                                </div>
                                <div className="flex w-[100%] sm:block mdsm:block lg:flex ">
                                    <div className="w-[30%] sm:w-[100%] mdsm:w-[100%] lg:w-[30%] mdsm:pt-6 sm:py-6 sm:px-4 center px-10">
                                        <p className="text-pureWhite sm:text-left text-[20px] sm:text-[16px] leading-[30px]">
                                            Our institution is an autonomous college where students, scholars, staff members, and alumni come together for academic pursuits. Our deep-rooted history serves as the cornerstone of our principles and beliefs.
                                        </p>
                                    </div>
                                    <div className="w-[70%] sm:w-[100%] mdsm:w-[100%] lg:w-[70%] mdsm:p-4 sm:p-4">
                                        <FacultyProfileSilder faculty={MBAAF.faculty} />
                                    </div>
                                </div>
                                <div className="p-6">
                                </div>
                            </div>
                        </div> */}


                </section>


                {/*Learn from Expect Section  */}


                {/* Internships  */}
                {/* <section>
                            <div className="container mx-auto">
                            <div className="AdmissionSection">
                                <div className="title text-secondary sm:text-[24px] pt-10 pb-4 tracking-wider text-[40px]">
                                Internships
                                </div>
                                </div>

                            </div>
                        </section> */}
                {/* Internships  */}

                {/*Eligibility  */}

                <section>
                    <div className="container mx-auto pt-0 pb-4">
                        <div className="">
                            <div className="title text-secondary mdsm:text-[30px] sm:text-[24px] pb-8 tracking-wider lg:text-[40px]">
                                Eligibility
                            </div>



                            <div className="w-[100%] lg:flex sm:block md:block ">

                                <div className="lg:w-[60%] md:w-[100%] sm:w-[100%] px-4">
                                    <p className="text-text-color text-base sm:text-sm text-left leading-[26px]">
                                        Candidates must pass 10+2 with physics and chemistry as compulsory subjects and anyone as optional, like mathematics, biology, or computer science, with 50% marks in the qualifying examination in the academic stream (or) any other equivalent qualification approved by the university.
                                    </p>

                                </div>
                                <div className="lg:w-[40%] md:w-[100%] sm:w-[100%] lg:pt-0 md:pt-4 md:px-4  lg:md-0 sm:pt-4 sm:px-4 lg:px-16">
                                    <img src={Eligibility} alt="Eligibility" />

                                </div>

                            </div>




                        </div>

                    </div>
                </section>

                {/*Eligibility  */}


                {/*Learn from Admission Section  */}

                <section class="category-title" id="admission">
                    <div className="container mx-auto sm:py-0">
                        <div className="AdmissionSection">
                            <div className="title text-secondary sm:text-[24px] sm:pb-0 mdsm:text-30px] pt-10 pb-4 tracking-wider text-[40px]">
                                Admission Process
                            </div>

                            {/* <h5 className="text-text-color px-4 sm:text-base text-h5 text-left">RVS IMSR Tech MBA Program offers equal admission opportunities to all deserving candidates.
                                    <b>The admission process consists of the following steps:</b>
                                </h5> */}
                            <div className="w-[100%] flex sm:block md:flex-wrap mdsm:flex-wrap sm:p-6 AdmissionSection">
                                <div className="w-[33%] sm:w-[100%] mdsm:w-[50%] md:w-[50%] lg:w-[33%] sm:p-4 pr-8 ">
                                    <div className="w-[100%] flex">
                                        <div className="w-[25%]">
                                            <div className="h-[66px]  text-[20px] bg-primary pt-[10px] tracking-wider font-bold text-white w-[60px]">
                                                01
                                            </div>
                                        </div>
                                        <div className="w-[75%] place-self-center pt-[36px] sm:text-[18px] title text-left text-text-color text-[22px]">
                                            Verify Your Eligibility
                                        </div>
                                    </div>
                                    <p className="text-text-color text-base text-left py-8 sm:py-4  sm:text-[14px] leading-[30px]">
                                        Confirm that you meet the eligibility criteria for the program.

                                    </p>

                                </div>
                                <div className="w-[33%] sm:w-[100%] mdsm:w-[50%] md:w-[50%] lg:w-[33%] sm:p-4 pr-8">
                                    <div className="w-[100%] flex">
                                        <div className="w-[25%]">
                                            <div className="h-[66px]  text-[20px] bg-primary pt-[10px] tracking-wider font-bold text-white w-[60px]">
                                                02
                                            </div>
                                        </div>
                                        <div className="w-[75%] place-self-center pt-[0px] sm:text-[18px] title text-left text-text-color text-[22px]">
                                            Fill out the Application Form

                                        </div>
                                    </div>
                                    <p className="text-text-color text-base text-left py-8 sm:py-4  sm:text-[14px] leading-[30px]">
                                        Complete and submit the application form, providing all the necessary information and supporting documents.

                                    </p>

                                </div>
                                <div className="w-[33%] sm:w-[100%] mdsm:w-[50%] md:w-[50%] lg:w-[33%] sm:p-4 pr-8">
                                    <div className="w-[100%] flex">
                                        <div className="w-[25%]">
                                            <div className="h-[66px]  text-[20px] bg-primary pt-[10px] tracking-wider font-bold text-white w-[60px]">
                                                03
                                            </div>
                                        </div>
                                        <div className="w-[75%]  place-self-center pt-[0px] sm:text-[18px] title text-left text-text-color text-[22px]">
                                            Verify Your Educational Credentials
                                        </div>
                                    </div>
                                    <p className="text-text-color text-base text-left py-8 sm:py-4  sm:text-[14px] leading-[30px]">
                                        Verify your educational credentials by providing the required certificates and transcripts.

                                    </p>

                                </div>

                            </div>

                            {/* <div className="py-3 pt-2 pb-4 sm:pt-2">
                                    <div className="w-[100%] pb-3 sm:px-6 lg:mx:auto flex sm:block mdsm:flex lg:flex">
                                        <div className=" sm:pb-4 mdsm:pb-6 lg:pb-0 mdsm:mr-10">
                                            <div className="relative ">
                                                <div className="text-text-color  pt-2 text-h4 sm:text-sm information text-left leading-[30px]">
                                                    For <span className="font-semibold title text-[22px] sm:text-[18px] tracking-wider text-primary">Admissions Contact</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text-left sm:w-[100%]">
                                        <p className="text-text-color text-h5 font-semibold pb-2">Principal, RVS College of Pharmaceutical Sciences,</p>
                                        <p className="text-text-color text-h5 font-semibold pb-2">242, Trichy Road, Sulur, Coimbatore</p>
                                        <p className="text-text-color"><span className="text-primary font-semibold">Phone -</span> 9500416761, 9443808361</p>
                                        <p className="text-text-color"><span className="text-primary font-semibold">Email ID-</span> <a href="mailto:pharmacy_office@rvsgroup.com">pharmacy_office@rvsgroup.com</a></p>
                                        <p className="text-text-color"><span className="text-primary font-semibold">Visit us -</span> <a href="https://www.rvscops.ac.in/" target="_blank" rel="noreferrer" className="hover:text-primary hover:underline hover:underline-offset-4">www.rvscons.ac.in</a></p>
                                    </div>
                                    <p className="text-left text-h3 text-secondary font-semibold pt-2">We also provide M. Pharm & Pharm.D.</p>
                                </div> */}

                            <div className="py-3 pt-0 pb-14 sm:pt-2">
                                <div className="w-[100%] sm:px-6 lg:mx:auto flex sm:block mdsm:flex lg:flex">
                                    <div className=" sm:pb-4 mdsm:pb-6 lg:pb-0 mdsm:mr-10">
                                        <div className="relative ">
                                            <div className="text-text-color pt-2 text-h4 sm:text-sm information text-left leading-[30px]">
                                                For more information about our curriculum and , <span className="font-semibold title text-[22px] sm:text-[18px] tracking-wider text-primary">fee structure</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sm:w-[100%] flex justify-between">
                                        <a href="#form">
                                            <button className="bg-primary mt-0  Transition text-[18px] borderradius hover:bg-[#081224] text-pureWhite  px-14 py-2 tracking-wider ">
                                                Download Brochure
                                            </button>
                                        </a>
                                    </div>

                                </div>
                            </div>
                            <p className="text-left text-h3 text-secondary font-semibold pt-2">We also provide M.Pharm and Pharm.D courses</p>
                        </div>


                    </div>

                </section>

                {/*Learn from Admission Section  */}


                {/*Learn from Internship Section  */}

                {/* <section className="internShip bg-primary ">
                        <div className="container mx-auto">
                            <Alumni />

                        </div>

                    </section> */}

                {/*Learn from Internship Section  */}

                {/*Learn from FAQ Section  */}
                {/* <section class="category-title" className="container mx-auto pb-14 sm:pb-0">
                        <div className="pt-10 pb-14 sm:pt-4 sm:pb-4">
                            <div className=" FAQsection" class="category-title" id="FAQsection">
                                <div className="title sm:text-[24px] mdsm:text-[30px] text-secondary tracking-wider lg:text-[40px]">
                                    FAQ
                                </div>

                                <div className="w-[100%] flex sm:block mdsm:block lg:flex">
                                    <div className="w-[50%] sm:w-[100%] mdsm:w-[100%] lg:w-[50%]">
                                        <div className="py-12 sm:px-2 sm:py-4">


                                            <ul className="accordion">
                                                {faqs.map((faq, index) => (
                                                    <AccordionItem
                                                        key={index}
                                                        onToggle={() => handleToggle(index)}
                                                        active={clicked === index} faq={faq}
                                                    // ...
                                                    />
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="w-[50%] sm:w-[100%] mdsm:w-[100%] px-8 py-16 lg:w-[50%] lg:py-16 sm:py-2 mdsm:py-2 sm:px-4">
                                        <img src={FAQ} alt="FAQ" className="mdsm:w-[100%]" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="sm:p-16">

                        </div>
                    </section> */}

                {/*Learn from FAQ Section  */}

                {/*Learn from Footer Section  */}

                <footer id="footer" class="category-title" ref={scrollHideDiv}>

                    <div className="py-6 pb-12 bg-[#151515] text-white ">
                        <div className="container mx-auto">
                            <div className="w-[100%] flex sm:px-4">
                                <div className="w-[70%] sm:w-[0%] mdsm:w-[0%]">
                                </div>
                                <div className="w-[30%] sm:w-[100%] mdsm:w-[100%]">
                                    <div className="text-h2 text-pureWhite sm:text-h4 text-left pb-2 tracking-wider pt-10 sm:text-left title">Location</div>
                                    <div className="block text-left sm:text-sm leading-[26px] tracking-wide">
                                        Unit III - RVS Health Science Campus
                                        <br />242, Trichy Road, Sulur,
                                        <br /> Coimbatore - 641 402.
                                    </div>
                                </div>

                            </div>
                            <p className="text-center pt-5 text-[12px] transformtech tracking-wider text-white">© {date} RVS College of Pharmaceutical Sciences Coimbatore. All rights reserved</p>
                            <hr className="h-[1px] text-white" />
                            <h2 className="text-white pt-2 text-center tracking-wider transformtech text-[12px]">Designed, Developed and Maintained By Transform Tech</h2>
                            <h3 className="text-white pb-3 text-center tracking-wider transformtech text-[12px]">A Unit of RVS Educational Trust</h3>
                        </div>
                        {/* copyright@rvsgroup.com */}

                    </div>
                </footer>


                {/*Learn from Footer Section  */}

                <section id="scrolling" className="sticky shadow bg-[#fff] bottom-0 z-10  w-[100%]">
                    <div className=" container mx-auto py-6 w-[100%]">
                        <div className="flex justify-end sm:justify-center w-[100%]">
                            <div className="w-[70%] desktop-view-logo">
                                <h1 className="text-[30px] transformtech text-left text-primary font-semibold  tracking-wide leading-[56px]">To know more about our <span className=" transformtech text-[30px] text-left text-primary font-semibold  tracking-wide leading-[56px]">Placement Support</span> </h1>
                            </div>
                            <div className="lg:w-[30%] sm:w-[100%] md:w-[100%] mdsm:w-[100%]">
                                <a href="#form">
                                    <button className="bg-primary  Transition text-[18px] borderradius hover:bg-[#081224] text-pureWhite  px-14 py-4 tracking-wider">Download Brochure</button>
                                </a>
                            </div>

                        </div>
                    </div>
                </section>
            </div>
        </>

    )
}

export default CoursePage