
export const MBAAF = {
    title: "Bachelor of Pharmacy",
    course_dis: "B.Pharm offers a comprehensive curriculum that builds a solid foundation in pharmaceutical sciences. It focuses on the vital connection between practitioners, patients, medicine design, manufacturing, and drug action. By completing this program, students are well-prepared to embark on careers in the pharmaceutical industry or pursue advanced studies in the field.",
    course_reg: {
        course_start: "July 2023",
        duration: "2 Years Full time",
        location: "Sulur, Coimbatore",
        specialization: "Dual",
    },
    scholarships: ["Merit", "Sports", "Extra Curricular Activities ", "Defence & Ex-Serviceman", "Community"],
    about_course: "The two-year MBA in Digital Marketing program offered by RVS IMSR is designed to equip students with the necessary skills and knowledge to succeed in the rapidly growing Digital Marketing Industry. The program covers various techniques of SEO, Content Marketing, SEM, and Social Media Marketing. The faculty comprises certified professionals from top business schools, ensuring a comprehensive and up-to-date curriculum. Students are provided with the creativity and communication skills needed to explore various career opportunities in the online marketing field. The course is ideal for individuals looking to advance their careers or explore business options in India and worldwide.",
    // programme:["Who wants to create a secure future in Digital Marketing.","Who wishes to learn more about Digital Marketing to make a mark in the field.",]
    program_curriculum: `MBA in Digital Marketing program provides a comprehensive education in the field, equipping you with the latest techniques and strategies to succeed in today's digital world. With dual specialization options and opportunities for internships and live projects, you'll have the competitive edge to advance your career in the fast-growing digital marketing industry.`,
    major_specialization: ["Human Anatomy and Physiology","Pharmacology","Pharmaceutics","Pharmaceutical Chemistry","Pharmaceutical Analysis","Pharmacognosy and Phytochemistry",],
    minor_specialization: ["Pharmacy Practice","Pathophysiology","Industrial Pharmacy","Pharmaceutical Jurisprudence","Communication Skills","Computer Applications in Pharmacy","Remedial Biology/Remedial Mathematics"],
    third_professional: ["Samhitha Adhyayana (Classical textbooks of Ayurveda)", "Prasuti Tantra evum Streeroga (Gynaecology and obstetrics)", "Kaumarabhrithyam (Pediatrics)", "Kayachikitsa (General medicine)", "Panchakarma", "Shalyatantra (Surgery)", "Shalakya Tantra (ENT and Ophthalmology)", "Research Methodology and Medical Statistics"],
    learn_from_experts: "Our institution is a residential college where students, scholars, staff members, and alums come together for academic pursuits. Our deep-rooted history serves as the cornerstone of our principles and beliefs.",

}