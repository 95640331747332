
import React from "react";
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
// import Home from './Pages/Home';
import "./Components/css/components.css"
import "./Components/css/responsive.css"
import CoursePage from './Pages/CoursePage';
import Thankyou from "./Pages/Thankyou";
import PageNotFound from "./Pages/PageNotFound";

function App() {

  return (
    <div className="App">
     <BrowserRouter>
        <Routes>
          <Route path="/" element={<CoursePage/>} />
          <Route path="/scholarships" element={<CoursePage/>} />
        {!localStorage.BPharmacy ? <></> : <Route path="/thankyou" element={<Thankyou/>} />}
          <Route path='*'  element={<PageNotFound/>}/>
          {/* <Route path="/" element={<Home/>} /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
