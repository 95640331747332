import React from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
// import NeedImage from '../Assets/needimage.png'
import Axxelent from '../Assets/placement/Axxelent.jpg'
import CaplinPoinc from '../Assets/placement/Caplin Poinc.jpg'
import Medreich from '../Assets/placement/Medreich.jpg'
import MicroLab from '../Assets/placement/Micro Lab.jpg'
import Myva from '../Assets/placement/myva.jpg'
import Pharmafabrikon from '../Assets/placement/Pharmafabrikon.jpg'
import 'owl.carousel/dist/assets/owl.theme.default.css';
// import Edelwise from "../Assets/DM Logo/Intership/1280px-Edelweiss_Group_logo.jpg"
// import ACS from "../Assets/DM Logo/Intership/ACS-logo.png"
// import MuthootFinance from "../Assets/DM Logo/Intership/Muthoot_Finance_Logo.jpg"
// import ShakthiFinance from "../Assets/DM Logo/Intership/Shakti-Finance-Logo-new.png"
// import SundaramFinance from "../Assets/DM Logo/Intership/Sundaram_Finance_Limited_Logo.jpg"


function InternShip() {
    const optionss = {
        items: 1,
        nav: true,
        navText: ["<div className='nav-btn prev-slide left-arrow arrow-button'><i class='arrow-left fa-solid fa-angle-left'></i></div>", "<div className='nav-btn next-slide'><i class='arrow-left fa-solid fa-angle-right'></i></div>"],
        rewind: true,
        autoplay: true,
        dots: true,
        dotsEach: true,
        dotData: true,
        responsive: {
            0: {
                items: 1
            },
            480: {
                items: 2
            },
            769: {
                items: 5
            }
        }
    };
    return (
        <div className="container mx-auto ">
            <div className="flex sm:block w-[100%]">
                <div className="w-[100%]   sm:block">
                    <div className="title pb-3 sm:text-[24px] mdsm:text-[30px] sm:px-4 text-secondary tracking-wider text-center lg:text-[40px]">
                        Placements and Internships
                    </div>
                    {/* <p className='text-primary text-h5 text-center text-left  sm-text-[14px] pb-4'>Students get to work on summer internships for eight weeks with core companies related to the specialization. Some of the companies are
                    </p> */}
                    <div className="w-[100%] pb-16 flex sm:p-4   sm:block lg:flex mdsm:block">
                        <div className="w-[100%] flex sm:w-[100%] lg:w-[100%] mdsm:w-[100%]">
                            <OwlCarousel className="owl-theme" {...optionss}>

                                <div className='item'>
                                    <div className='my-4 px-4'>
                                        <img src={Axxelent} alt="Zoho" />
                                    </div>
                                </div>

                                <div className='item '>
                                    <div className='my-4 px-4'>
                                        <img src={CaplinPoinc} alt="webon h-[100%]" />
                                    </div>
                                </div>


                                <div className='item'>
                                    <div className='my-4 px-4'>
                                        <img src={Medreich} alt="TT" />
                                    </div>
                                </div>


                                <div className='item'>
                                    <div className='my-4 px-4'>
                                        <img src={MicroLab} alt="Infinix" />
                                    </div>
                                </div>


                                <div className='item'>
                                    <div className='my-4 px-4'>
                                        <img src={Myva} alt="venpep" />
                                    </div>
                                </div>

                                <div className='item'>
                                    <div className='my-4 px-4'>
                                        <img src={Pharmafabrikon} alt="venpep" />
                                    </div>
                                </div>
                            </OwlCarousel>
                            <div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InternShip